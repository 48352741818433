import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuPreset = lazy(
  () => import("./menu-preset-CPjVqPQa.js").then((module) => ({
    default: module.MenuPreset
  }))
);
function MenuPreset({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuPreset, { ...props });
}
const useMenuPresetContent = () => {
  return useMemo(
    () => ({
      menuPreset: {
        component: MenuPreset
      }
    }),
    []
  );
};
export {
  LazyMenuPreset as MenuPreset,
  useMenuPresetContent
};
